<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" class="py-0">
        <v-card class="px-7 py-7 rounded-lg" id="card-custom">
          <p class="headline-color">Data Presensi</p>
          <v-divider />
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="6" sm="6" xs="12">
              <v-btn-toggle v-model="type" class="mt-5" id="btn-type" mandatory>
                <v-btn small color="#1792E6" class="px-4 py-5">
                  <span class="text-capitalize font-weight-regular"
                    >Berdasarkan Unit Kerja</span
                  >
                </v-btn>
                <v-btn small color="#1792E6" class="px-4 py-5">
                  <span class="text-capitalize font-weight-regular"
                    >Berdasarkan Unit Kerja (Tree View)</span
                  >
                </v-btn>
                <v-btn small color="#1792E6" class="px-4 py-5">
                  <span class="text-capitalize font-weight-regular"
                    >Berdasarkan Nama</span
                  >
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="end">
            <template v-if="type == 0">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units"
                  v-model="unit"
                  :loading="loadingUnit"
                  placeholder="Unit Utama"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_utama_id"
                  clearable
                  @change="changeUnit(1)"
                  @click:clear="changeUnit(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units2"
                  v-model="unit2"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_2_id"
                  clearable
                  @change="changeUnit(2)"
                  @click:clear="changeUnit(2)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units3"
                  v-model="unit3"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_3_id"
                  clearable
                  @change="changeUnit(3)"
                  @click:clear="changeUnit(3)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-autocomplete
                  :items="units4"
                  v-model="unit4"
                  :loading="loadingUnit"
                  placeholder="Unit Kerja"
                  outlined
                  dense
                  hide-details
                  item-text="singkatan"
                  item-value="unit_kerja_4_id"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      placeholder="Bulan"
                      append-icon="event"
                      outlined
                      dense
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    locale="id"
                    no-title
                    scrollable
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </template>
            <template v-else-if="type == 1">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      placeholder="Bulan"
                      append-icon="event"
                      outlined
                      dense
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    locale="id"
                    no-title
                    scrollable
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </template>
            <template v-else-if="type == 2">
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-text-field
                  placeholder="Masukan Nama Pegawai"
                  v-model="search"
                  v-on:keyup.enter="getListEmployee"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      placeholder="Bulan"
                      append-icon="event"
                      outlined
                      dense
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    locale="id"
                    no-title
                    scrollable
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </template>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-btn color="#FBB005" class="px-10" @click="getListEmployee">
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions class="py-0">
            <v-spacer />
            <v-switch
              class="ma-0 ml-5 mb-2"
              v-model="displayDetail"
              label="Munculkan Detail"
              color="primary"
              hide-details
            ></v-switch>
          </v-card-actions>
          <!-- <v-card-actions>
            <v-spacer />
            <v-btn
              @click="visibleColumn = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">view_column</v-icon>
              Sesuaikan Kolom
            </v-btn>
            <v-btn
              @click="visible = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">table_chart</v-icon>
              Format
            </v-btn>
            <v-btn
              @click="visibleFilter = true"
              small
              outlined
              class="px-4 font-weight-regular"
              color="#555555"
            >
              <v-icon class="mr-2">filter_list_alt</v-icon>
              Filter
            </v-btn>
          </v-card-actions> -->
          <v-row>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              v-if="type == 1"
            >
              <v-treeview
                :value="selection"
                :active.sync="active"
                selection-type="independent"
                :open.sync="open"
                :items="unitsTree"
                item-text="singkatan"
                color="primary"
                @input="changeUnitTree"
                open-on-click
                return-object
                dense
                selectable
              >
              </v-treeview>
            </v-col>
            <v-col
              cols="12"
              :xl="type == 1 ? '9' : '12'"
              :lg="type == 1 ? '9' : '12'"
              :md="type == 1 ? '8' : '12'"
              :sm="type == 1 ? '6' : '12'"
              xs="12"
            >
              <template v-if="displayDetail">
                <v-card-actions class="py-0">
                  <v-btn
                    color="blue-grey"
                    class="ma-2 pr-4 white--text"
                    :disabled="options.page == 1 && selectedIndex == 0"
                    @click="goToBackPage"
                  >
                    <v-icon left dark>
                      chevron_left
                    </v-icon>
                    Back
                  </v-btn>
                  <v-spacer />
                  <p class="pa-0">
                    {{ selectedIndex + 1 }} / {{ options.itemsPerPage }}
                  </p>
                  <v-spacer />
                  <v-btn
                    color="blue-grey"
                    class="ma-2 pl-4 white--text"
                    :disabled="selectedIndex > employees.length - 1"
                    @click="goToNextPage"
                  >
                    Next
                    <v-icon right dark>
                      chevron_right
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <DetailComponent
                  ref="detailComponent"
                  :id="selectedNIP"
                  :dateMonthProps="date"
                  hideFilter
                />
                <div class="text-center">
                  <v-pagination
                    v-model="options.page"
                    :length="pageCount"
                    :total-visible="5"
                    circle
                  ></v-pagination>
                </div>
              </template>
              <v-data-table
                v-else
                id="table-custom"
                class="mt-10"
                multi-sort
                :headers="headers"
                :items="employees"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalItem"
                :footer-props="{
                  'items-per-page-options': rowsPerPageItems
                }"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.NIP`]="{ item }">
                  <span
                    class="hover-primary"
                    @click="
                      getToPage('presensi.detail', {
                        params: { employeeId: item.NIP },
                        query: { dateMonth: date }
                      })
                    "
                    >{{ item.NIP }}</span
                  >
                </template>
                <template v-slot:no-data>
                  <ContentNotFound
                    message="Data pegawai dan presensi tidak ada"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visibleFilter" persistent max-width="400">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visibleFilter = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">filter_list_alt</v-icon>
          Filter
        </v-card-text>
        <v-divider class="mb-2" />
        <v-row>
          <v-col
            cols="6"
            class="pb-0"
            v-for="(value, propertyName, index) in filter"
            :key="index"
          >
            <v-checkbox hide-details v-model="filter[propertyName]">
              <template #label>
                {{ propertyName | toTitle }}
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="getListEmployee"
            >Cari</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">table_chart</v-icon>
          Format
        </v-card-text>
        <v-divider class="mb-2" />
        <v-radio-group v-model="format" mandatory>
          <v-row>
            <v-col
              cols="6"
              class="pb-0"
              v-for="(item, index) in formats"
              :key="index"
            >
              <v-radio
                :value="item.value"
                class="font-weight-regular"
                hide-details
              >
                <template #label>
                  <span class="subtitle-2 font-weight-regular">{{
                    item.label
                  }}</span>
                </template>
              </v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="visible = false"
            >Cari</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleColumn" persistent max-width="750">
      <v-card class="pa-5 rounded-lg">
        <v-btn @click="visibleColumn = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          <v-icon class="mr-2">view_column</v-icon>
          Sesuaikan Kolom
        </v-card-text>
        <v-divider class="mb-5" />
        <v-text-field
          placeholder="Cari Kolom"
          v-model="searchColumn"
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-row>
          <v-col
            cols="3"
            class="pb-0"
            v-for="(item, index) in headersTemp"
            :key="index"
          >
            <v-checkbox
              v-model="item.active"
              :value="item.value"
              hide-details
              dense
            >
              <template #label>
                <span class="subtitle-2 font-weight-regular">{{
                  item.text
                }}</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12 subtitle-2 text-capitalize"
            @click="visibleColumn = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            @click="handleApply"
            >Buat</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
import DataUtamaService from "@/services/resources/data.utama.service";
const ContentNotFound = () => import("@/components/Content/NotFound");
const DetailComponent = () => import("./DetailComponent.vue");
import store from "@/store";
import { mapFilterRootField } from "@/store/helpers";

export default {
  components: {
    ContentNotFound,
    DetailComponent
  },
  data() {
    return {
      selection: [],
      active: [],
      open: [],
      visible: false,
      visibleFilter: false,
      visibleColumn: false,
      displayDetail: false,
      selectedNIP: null,
      selectedIndex: 0,
      colFilter: null,
      filter: {
        aktif: true,
        berhenti: false,
        meninggal: false,
        pensiun: false,
        pindah: false
      },
      format: null,
      formats: [
        { label: "Semua", value: "Semua" },
        { label: "Masa Kerja", value: "Masa Kerja" },
        { label: "Pejabat", value: "Pejabat" },
        { label: "Pensiun", value: "Pensiun" },
        { label: "Jabatan", value: "Jabatan" },
        { label: "Kepangkatan", value: "Kepangkatan" },
        { label: "Kenaikan Pangkat", value: "Kenaikan Pangkat" },
        { label: "Pendidikan", value: "Pendidikan" },
        { label: "Kenaikan Gaji", value: "Kenaikan Gaji" }
      ],
      loadingUnit: false,
      unitsTree: [],
      units: [],
      units2: [],
      units3: [],
      units4: [],
      menu: false,
      headersColumn: [],
      headersTemp: [],
      headers: [
        { text: "NIP", value: "NIP", sortable: true, align: "center" },
        { text: "NAMA", value: "nama", sortable: true, align: "center" },
        {
          text: "Unit Kerja",
          value: "unitkerja2",
          sortable: false,
          align: "center"
        },
        { text: "JABATAN", value: "jabatan", sortable: false, align: "center" }
      ],
      totalItem: 0,
      pageCount: 0,
      loading: true,
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      employees: [],

      // Form
      // search: null,
      searchColumn: null
    };
  },
  watch: {
    searchColumn(val) {
      this.headersTemp = this.headersColumn.filter(d =>
        d.text.toLowerCase().includes(val.toLowerCase())
      );
    },
    options: {
      handler() {
        this.fetchListDebounce(this.getListEmployee);
      },
      deep: true
    },
    type(val) {
      if (val == 0) {
        this.search = null;
      } else if (val == 1) {
        this.unit = null;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      }
    }
  },
  computed: {
    ...mapFilterRootField({
      type: "pegawai.type",
      unit: "pegawai.unit",
      unit2: "pegawai.unit2",
      unit3: "pegawai.unit3",
      unit4: "pegawai.unit4",
      date: "pegawai.date",
      search: "pegawai.search"
    })
  },
  methods: {
    goToBackPage() {
      const selectedIndex = this.selectedIndex - 1;
      if (selectedIndex < 0) {
        this.options.page = this.options.page - 1;
        this.selectedIndex = 0;
      } else {
        this.selectedNIP = this.employees[selectedIndex].NIP;
        this.selectedIndex = selectedIndex;
        setTimeout(() => {
          this.$refs.detailComponent?.emitPage();
        }, 500);
      }
    },
    goToNextPage() {
      const selectedIndex = this.selectedIndex + 1;
      if (selectedIndex >= 10) {
        this.options.page = this.options.page + 1;
        this.selectedIndex = 0;
      } else {
        this.selectedNIP = this.employees[selectedIndex].NIP;
        this.selectedIndex = selectedIndex;
        setTimeout(() => {
          this.$refs.detailComponent?.emitPage();
        }, 500);
      }
    },
    changeUnitTree(val) {
      val.map(d => {
        if (d.unit_utama_id) {
          this.unit = d.unit_utama_id;
          this.unit2 = null;
          this.unit3 = null;
          this.unit4 = null;
        }
        if (d.unit_kerja_2_id) {
          this.unit = null;
          this.unit2 = d.unit_kerja_2_id;
          this.unit3 = null;
          this.unit4 = null;
        }
        if (d.unit_kerja_3_id) {
          this.unit = null;
          this.unit2 = null;
          this.unit3 = d.unit_kerja_3_id;
          this.unit4 = null;
        }
        if (d.unit_kerja_4_id) {
          this.unit = null;
          this.unit2 = null;
          this.unit3 = null;
          this.unit4 = d.unit_kerja_4_id;
        }
      });
      this.selection = val.slice(-1);
    },
    handleApply() {
      this.headers = this.headersTemp.filter(d => d.active != null);
      this.visibleColumn = false;
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitsTree = data.map(d => {
                return {
                  ...d,
                  id: d.unit_utama_id,
                  children:
                    d.unit_kerja2.length == 0
                      ? undefined
                      : d.unit_kerja2.map(d2 => {
                          return {
                            ...d2,
                            id: d2.unit_kerja_2_id,
                            children:
                              d2.unit_kerja3.length == 0
                                ? undefined
                                : d2.unit_kerja3.map(d3 => {
                                    return {
                                      ...d3,
                                      id: d3.unit_kerja_3_id,
                                      children:
                                        d3.unit_kerja4.length == 0
                                          ? undefined
                                          : d3.unit_kerja4.map(d4 => {
                                              return {
                                                ...d4,
                                                id: d4.unit_kerja_4_id
                                              };
                                            })
                                    };
                                  })
                          };
                        })
                };
              });
              this.units = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListEmployee() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      let status = [];
      this.filter.aktif ? status.push("Aktif") : null;
      this.filter.berhenti ? status.push("Berhenti") : null;
      this.filter.meninggal ? status.push("Meninggal") : null;
      this.filter.pensiun ? status.push("Pensiun") : null;
      this.filter.pindah ? status.push("Pindah") : null;
      try {
        this.loading = true;
        await PegawaiService.getListPresensi({
          filter: {
            search: this.type == 2 ? this.search : "",
            status,
            unit_utama_id: this.type == 2 ? "" : this.unit,
            unit_kerja_2_id: this.type == 2 ? "" : this.unit2,
            unit_kerja_3_id: this.type == 2 ? "" : this.unit3,
            unit_kerja_4_id: this.type == 2 ? "" : this.unit4
          },
          period: this.$moment(this.date).format("YYYYMM"),
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let employees = list;
              employees.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });
              this.visibleFilter = false;
              this.employees = employees;
              this.selectedIndex = 0;
              this.selectedNIP = employees[this.selectedIndex]?.NIP;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
              setTimeout(() => {
                this.$refs.detailComponent?.emitPage();
              }, 500);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    if (this.type == 1) {
      this.unit = null;
      this.unit2 = null;
      this.unit3 = null;
      this.unit4 = null;
    }
    this.headersTemp = this.headers.map(d => {
      d.active = d.value;
      return d;
    });
    this.headersColumn = this.headersTemp;
    this.getListUnit();
  },
  beforeRouteEnter(to, from, next) {
    const { NIP, role } = store.getters["auth/currentUser"];
    if (role === "USER") {
      next({
        name: "presensi.detail",
        params: { employeeId: NIP }
      });
    } else {
      next();
    }
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
.v-treeview {
  font-size: 0.775rem;
}
</style>
